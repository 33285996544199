import { observable, action, makeObservable } from 'mobx';

import request from '~/bin/httpRequest';
import { apiAddons, queryFunction } from '~/store/Query/api';

export const addonsList = async () => {
  const res = await queryFunction('addons', apiAddons, false);
  if (res.success) {
    res.data = [res.data.addons];
  }
  return res;
};

class AddonStore {
  autoSuggestResult = observable.map();

  constructor() {
    makeObservable(this, {
      autoSuggestResult: observable,
      catalog: action
    });
  }

  catalog(value) {
    const options = {
      method: 'GET',
      url: `${process.env.NEXT_PUBLIC_MONGO_API}${process.env.NEXT_PUBLIC_MERCHANT}/productsByCategory%2F${value}?lang=${process.env.NEXT_PUBLIC_LOCALE}&currency=${process.env.NEXT_PUBLIC_CURRENCY}`,
    };

    return request.genericHandler(options, null).then((res) => {
      let callback = { action: 'catalog', success: false };
      if (!res.error) {
        // execute clear product list;
        callback = Object.assign({}, callback, { data: res.data.data, success: true });
      } else {
        callback = Object.assign({}, callback, { error: res.error });
      }
      this.catalogResponse = callback;
      return callback;
    });
  }
}

export default new AddonStore();
