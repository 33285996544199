import { observable, action, makeObservable } from 'mobx';

class ModalStore {
  flag = false;
  PPopup = null;
  PPopups = null;
  popupList = {
    cart: true,
  };

  updateHeaderImage = () => console.log('image updated');

  openPopup = (type) => {
    this.PPopup = this.popupList[type];
  };

  closePopup = () => {
    this.PPopup = null;
  };
  //Otro modal
  popupLists = {
    descripcion: true,
  };
  openPopups = (type) => {
    this.PPopups = this.popupLists[type];
  };

  closePopups = () => {
    this.PPopups = null;
  };
  // modal para token en pospago
  popupListToken = {
    tokenModal: true,
  };
  openPopToken = (type) => {
    this.PopupToken = this.popupListToken[type];
  };

  closePopToken = () => {
    this.PopupToken = null;
  };

  constructor() {
    makeObservable(this, {
      flag: observable,
      PPopup: observable,
      PPopups: observable,
      popupList: observable,
      updateHeaderImage: action,
      openPopup: action,
      closePopup: action,
      popupLists: observable,
      openPopups: action,
      closePopups: action,
      popupListToken: observable,
      openPopToken: action,
      closePopToken: action
    });
  }
}

export default new ModalStore();

