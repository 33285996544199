import { macroSort } from '#/renderer/utils/home';
import request from '~/bin/httpRequest';
import { apiCategories, getConfigMC, queryClient, queryFunction } from '~/store/Query/api';

class NavigationStore {

  getCategories() {
    return queryFunction('getCategories', apiCategories);
  }

  getMacroCategories(loading) {
    const options = {
      method: 'GET',
      url: `${process.env.NEXT_PUBLIC_MONGO_API}${process.env.NEXT_PUBLIC_MERCHANT}/macrocategories/`,
      loading,
    };
    return queryClient.fetchQuery({ queryKey: ['macrocategories'], queryFn: () => request.genericHandler(options).then(async (res) => {
      let callback = { action: 'getMacroCategories', success: false };
      if (!res.error) {
        const config = await getConfigMC();
        if (config.ok) {
          macroSort(res.data.data, config.body);
        }
        callback = { ...callback, data: res.data, success: true };
      } else {
        callback = { ...callback, error: res.error };
      }
      return callback;
    })});
  }
}

const navigationStore = new NavigationStore();
export default navigationStore;
